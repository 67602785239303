import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Box, Button, IconButton, MenuItem, Tab, Tabs, Typography, } from '@mui/material';
import { filterNavConfigByRole } from '../../../utils/navConfig';
import navConfig from '../nav/config-navigation';
import { useAuthContext } from '../../../auth/useAuthContext';
import { useLocation } from 'react-router';
import { Link } from 'react-router-dom';
import React, { useState } from 'react';
import MenuPopover from '../../../components/menu-popover';
import Iconify from '../../../components/iconify';
import { Link as RouterLink } from 'react-router-dom';
const activeLinkStyles = {
    background: 'linear-gradient(120deg, #2788E7 21.17%, #785EE0 79.91%)',
    boxShadow: '0px 4px 9.2px 0px #A29BF8, 0px 4px 0px 0px rgba(72, 149, 227, 0.39)',
    color: '#fff',
    width: '100%',
    height: 50,
    p: 2,
    whiteSpace: 'nowrap',
    wordBreak: 'keep-all',
};
const defaultLinkStyles = {
    backgroundColor: 'white',
    width: '100%',
    p: 2,
    height: 50,
    flex: 1,
    whiteSpace: 'nowrap',
    wordBreak: 'keep-all',
};
const scrollButtonStyles = {
    '&.MuiTabs-scrollButtons': {
        color: '#2788E7', // Customize the arrow color
    },
};
function NavBarHorizontal() {
    const { user } = useAuthContext();
    const { pathname } = useLocation();
    const [value, setValue] = React.useState(0);
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    const filteredNavConfig = filterNavConfigByRole(navConfig, user?.roles[0]?.code, user?.roles);
    const [open, setOpen] = useState(null);
    const [openMenu, setOpenMenu] = useState(null);
    const [openMenuTitle, setOpenMenuTitle] = useState(null);
    const handleOpen = (menuList, event, title) => {
        setOpen(event.currentTarget);
        setOpenMenu(menuList);
        setOpenMenuTitle(title);
    };
    const handleClose = () => {
        setOpen(null);
        setOpenMenu(null);
        setOpenMenuTitle(null);
    };
    return (_jsx(Tabs, { value: value, onChange: handleChange, variant: "scrollable", scrollButtons: "auto", "aria-label": "scrollable auto tabs example", sx: {
            p: 1,
            '& .MuiTabs-flexContainer': { gap: '10px' },
        }, TabIndicatorProps: { style: { display: 'none' } }, ScrollButtonComponent: (props) => {
            const isRightDirection = props.direction === 'right';
            return (_jsx(IconButton, { sx: isRightDirection ? { ml: 2 } : { mr: 2 }, disabled: props.disabled, ...props, children: _jsx(Iconify, { icon: isRightDirection ? 'lucide:chevron-right' : 'lucide:chevron-left' }) }));
        }, children: filteredNavConfig[0].items &&
            filteredNavConfig[0].items.map((list) => {
                const hasChildren = list.children && list.children.length > 0;
                const formatedLinks = list.children?.map((item) => item.path);
                const isActiveLink = hasChildren
                    ? formatedLinks.includes(pathname)
                    : pathname.includes(list?.path);
                return (_jsx(Tab, { sx: { mr: '0 !important', borderRadius: 1 }, label: _jsxs(Box, { sx: { flex: 1 }, children: [_jsx(Link, { to: !list?.children || list?.children.length === 0 ? list?.path : null, style: { textDecoration: 'none' }, children: _jsx(Button, { variant: "text", startIcon: list.icon, endIcon: hasChildren ? (_jsx(Iconify, { icon: "lucide:chevron-down", sx: {
                                            transform: openMenuTitle === list.title ? 'rotate(180deg)' : 'rotate(0deg)',
                                            transition: 'transform .3s ease',
                                        } })) : null, onClick: (e) => hasChildren && handleOpen(list?.children, e, list.title), sx: isActiveLink ? activeLinkStyles : defaultLinkStyles, children: list.title }) }), hasChildren && (_jsx(MenuPopover, { open: open, onClose: handleClose, sx: {
                                    width: 230,
                                    p: 0.5,
                                    boxShadow: '0 0 10px 0 rgba(145, 158, 171, 0.1)',
                                }, children: openMenu?.map((option) => (_jsxs(MenuItem, { component: RouterLink, to: option?.path || '/dashboard/app', onClick: () => {
                                        handleClose();
                                    }, sx: { display: 'flex', alignItems: 'center', gap: 1 }, children: [_jsx(Box, { color: "#2788E7", sx: { display: 'flex', alignItems: 'center' }, children: React.isValidElement(option?.icon) ? option.icon : null }), _jsx(Typography, { variant: "button", sx: { textTransform: 'capitalize' }, noWrap: true, color: "primary", children: option?.title })] }, option.title))) }, list.title))] }, list?.title + list?.path) }));
            }) }));
}
export default NavBarHorizontal;
